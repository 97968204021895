import {Component} from '@angular/core';
import {init as initFullStory} from '@fullstory/browser';
import {environment} from '@simplifi/env/environment';

@Component({
  selector: 'simplifi-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  constructor() {
    if (environment.fsOrgId) {
      initFullStory({
        orgId: environment.fsOrgId,
        debug: environment.fsDebug,
        namespace: environment.fsNamespace,
        recordCrossDomainIFrames: true,
        recordOnlyThisIFrame: false,
        devMode: environment.fsDevMode,
      });
    }
  }
}
