import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {ThemeModule} from './theme/theme.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {CoreModule} from './core';
import {
  NgxUiLoaderHttpModule,
  NgxUiLoaderModule,
  NgxUiLoaderRouterModule,
} from 'ngx-ui-loader';
import {environment} from '@simplifi/env/environment';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CoreModule,
    ThemeModule,
    BrowserAnimationsModule,
    NgxUiLoaderModule.forRoot({hasProgressBar: false}),
    NgxUiLoaderRouterModule,
    NgxUiLoaderHttpModule.forRoot({
      showForeground: true,
      excludeRegexp: [
        `${environment.planFacadeUrl}/.*/search`,
        `${environment.planFacadeUrl}/.*/zip-codes`,
        `${environment.chatFacadeApiUrl}/.*/channels`,
        `${environment.planFacadeUrl}/.*/state/sessions/.*`,
        `${environment.planFacadeUrl}/.*/zipcodes`,
        `${environment.planFacadeUrl}/.*/cities`,
        `${environment.planFacadeUrl}/.*/states`,
        `${environment.planFacadeUrl}/.*/locations`,
        `${environment.planFacadeUrl}/.*/state`,
        `${environment.tenantUserFacade}/auth/token-refresh`,
      ],
    }),
  ],
  bootstrap: [AppComponent],
  providers: [provideAnimationsAsync()],
})
export class AppModule {}
