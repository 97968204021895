<router-outlet></router-outlet>
<ngx-ui-loader
  [fgsTemplate]="foregroundSpinner"
  [bgsTemplate]="backgroundSpinner"
></ngx-ui-loader>

<ng-template #foregroundSpinner>
  <simplifi-page-loader [diameter]="50"></simplifi-page-loader>
</ng-template>

<ng-template #backgroundSpinner>
  <simplifi-page-loader [diameter]="50"></simplifi-page-loader>
</ng-template>
